<template>
    <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <div class="itemTask">
          <div class="itemTask_head" v-if="title !== '编辑任务'">
            <span>任务列表（{{list.length}}）</span>
            <el-button type="primary" size="small" @click="addItem()">添加</el-button>
          </div>
          <div class="itemTask_item" v-for="(item, index) in list" :key="index">
            <el-form-item label="门店" v-if="$store.state.userInfo.roles[0] === 'admin' || $store.state.userInfo.roles[0] === 'boss'">
              <el-select v-model="item.storeId" @change="changeStore(item)" placeholder="请选择门店">
                <el-option
                  v-for="item in store"
                  :key="item.id"
                  :label="item.storeName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务模板">
              <el-select v-model="item.templateId" @change="changeTaskTemplate($event, item)" @visible-change="focusTaskTemplate($event, item.storeId)" placeholder="请选择任务模板">
                <el-option
                  v-for="item1 in taskTemplate"
                  :key="item1.id"
                  :label="item1.title"
                  :value="item1.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-button type="danger" icon="el-icon-delete" v-if="title !== '编辑任务'" circle class="itemTask_item_dele" @click="dele(index)"></el-button>
            <el-form-item label="任务指派人">
              <el-cascader :options="options" v-model="item.assignedBy" @change="onSelectAssignedBy($event)" @visible-change="focusAssignedBy($event, item.storeId)" :show-all-levels="false"></el-cascader>
            </el-form-item>
            <el-form-item label="任务执行开始时间">
              <el-time-picker v-model="item.startTime" value-format="HH:mm:ss" placeholder="请输入任务开始时间"></el-time-picker>
            </el-form-item>
            <el-form-item label="任务执行结束时间">
              <el-time-picker v-model="item.endTime" value-format="HH:mm:ss" placeholder="请输入任务结束时间"></el-time-picker>
            </el-form-item>
            <el-form-item label="开始日期">
              <el-date-picker v-model="item.startDate" type="date" value-format="yyyy-MM-dd" placeholder="请输入任务开始日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期">
              <el-date-picker v-model="item.endDate" type="date" value-format="yyyy-MM-dd" placeholder="请输入任务结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="开始前多少分钟创建当日任务">
              <el-input v-model="item.beginCreateTime" type="number" placeholder="请输入时间（单位分钟）"></el-input>
            </el-form-item>
            <el-form-item label="开始前多少分钟未点击确认，发送时间提醒">
              <el-input v-model="item.beginConfirmTime" type="number" placeholder="请输入时间（单位分钟）"></el-input>
            </el-form-item>
            <el-form-item label="监督间隔">
              <el-input v-model="item.intervalTime" type="number" placeholder="请输入时间（单位分钟）"></el-input>
            </el-form-item>
            <el-form-item label="任务开始前多少分钟发送准备的消息">
              <el-input v-model="item.beginStartTime" type="number" placeholder="请输入时间（单位分钟）"></el-input>
            </el-form-item>
            <el-form-item label="是否需要确认">
              <el-switch
                v-model="item.isComfirm"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0">
              </el-switch>
            </el-form-item>
            <el-form-item label="任务内容">
              <el-input type="textarea" :rows="4" v-model="item.content" placeholder="请输入备注" v-trim/>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </GlobalWindow>
  </template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { fetchListAll } from '@/api/ext/store'
import { create, updateById, treeUserList } from '@/api/ext/taskRoutine'
import { fetchList } from '@/api/ext/taskTemplate.js'
export default {
  name: 'OperaTaskRoutineWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        title: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入任务标题' }
        ]
      },
      list: [
        {
          storeId: '',
          templateId: '',
          title: '',
          assignedBy: '',
          startTime: '',
          endTime: '',
          startDate: '',
          endDate: '',
          isComfirm: 1,
          content: '',
          beginConfirmTime: '',
          beginCreateTime: '',
          beginStartTime: '',
          intervalTime: ''
        }
      ],
      info: {},
      store: [], // 门店列表
      options: [], // 任务指派人
      taskTemplate: [] // 任务模板
    }
  },
  created () {
    this.config({
      api: '/ext/taskRoutine',
      'field.id': 'id'
    })
    // this.gettreeUserList()
  },
  methods: {
    /**
       * 打开窗口
       *
       * @param title 窗口标题
       * @param target 行对象（仅编辑需该参数）
       */
    open (title, target) {
      this.title = title
      this.options = []
      this.taskTemplate = []
      this.form.id = null
      if (title === '编辑任务') {
        this.list = [target]
        this.info = target
        this.form.id = target.id
        if (this.$store.state.userInfo.roles[0] !== 'admin' || this.$store.state.userInfo.roles[0] !== 'boss') {
          this.store = [{ test: 1 }]
        }
        let timer = setInterval(() => {
          if (this.store.length > 0) {
            clearInterval(timer)
            timer = null
            this.gettreeUserList(this.list[0].storeId)
            this.getTaskTemplate(this.list[0].storeId)
          }
        }, 500)
      } else {
        this.list = [
          {
            storeId: '',
            templateId: '',
            title: '',
            assignedBy: '',
            startTime: '',
            endTime: '',
            startDate: '',
            endDate: '',
            isComfirm: 1,
            content: '',
            beginCreateTime: 120,
            beginConfirmTime: '',
            beginStartTime: '',
            intervalTime: ''
          }
        ]
      }
      this.visible = true
      if (this.$store.state.userInfo.roles[0] === 'admin' || this.$store.state.userInfo.roles[0] === 'boss') {
        // 获取门店
        fetchListAll({})
          .then(res => {
            this.store = res
          })
      }
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form[this.configData['field.id']] = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
      })
      console.log('open list', this.list)
    },
    // 切换门店
    changeStore (item) {
      item.templateId = ''
      item.assignedBy = ''
      this.options = []
      this.taskTemplate = []
    },
    onSelectAssignedBy (event) {
      console.log('onSelectAssignedBy', event)
    },
    // 点击任务指派人
    focusAssignedBy (event, storeId) {
      console.log('focusAssignedBy', storeId)
      if (event) {
        if (!storeId) {
          this.$tip.warning({ message: '请先选择门店' })
          return
        }
        this.gettreeUserList(storeId)
      }
    },
    // 点击任务模板
    focusTaskTemplate (event, storeId) {
      if (event) {
        if (!storeId) {
          this.$tip.warning({ message: '请先选择门店' })
          return
        }
        this.getTaskTemplate(storeId)
      }
    },
    // 切换任务模板
    changeTaskTemplate (e, row) {
      this.taskTemplate.forEach(item => {
        if (e === item.id) {
          row.content = item.content
          row.title = item.title
        }
      })
    },
    // 添加任务
    addItem () {
      this.list.unshift({
        storeId: '',
        templateId: '',
        title: '',
        assignedBy: '',
        startTime: '',
        endTime: '',
        startDate: '',
        endDate: '',
        isComfirm: 1,
        content: '',
        beginConfirmTime: '',
        beginCreateTime: 120,
        beginStartTime: '',
        intervalTime: ''
      })
      this.list.forEach(row => {
        if (!row.content) {
          row.content = this.taskTemplate[0].content
        }
      })
    },
    dele (index) {
      if (this.list.length === 1) return this.$tip.warning({ message: '至少保留一项任务' })
      this.list.splice(index, 1)
    },
    // 获取消息模板
    getTaskTemplate (storeId) {
      fetchList({
        page: -1,
        capacity: 1000,
        model: { storeId, sort: 'TITLE asc' }
      }).then(res => {
        this.taskTemplate = res.records
      })
    },
    // 获取数结构
    gettreeUserList (storeId) {
      treeUserList({ storeId })
        .then(res => {
          this.options = res
          console.log('gettreeUserList options', this.options)
        })
    },
    // 提交
    confirm () {
      for (let i = 0; i < this.list.length; i++) {
        if (!this.list[i].storeId) return this.$tip.warning({ message: `第${i + 1}项任务请选择门店` })
        if (!this.list[i].templateId) return this.$tip.warning({ message: `第${i + 1}项任务请选择任务模板` })
        if (!this.list[i].assignedBy) return this.$tip.warning({ message: `第${i + 1}项任务请选择任务指派人` })
        if (!this.list[i].startTime) return this.$tip.warning({ message: `第${i + 1}项任务请选择任务开始时间` })
        const current = new Date().getTime()
        const start = new Date(this.list[i].startTime).getTime()
        if (start < current) {
          return this.$tip.warning({ message: `第${i + 1}项任务开始时间不能小于当前时间` })
        }
        if (!this.list[i].endTime) return this.$tip.warning({ message: `第${i + 1}项任务请选择任务结束时间` })
        const start1 = new Date(this.list[i].startTime).getTime()
        const end = new Date(this.list[i].endTime).getTime()
        const val = (end - start1) / 1000 / 60
        if (val < 30) {
          return this.$tip.warning({ message: `第${i + 1}项任务起止时间查不能小于30分钟` })
        }
        if (!this.list[i].content) return this.$tip.warning({ message: `第${i + 1}项任务请输入任务内容` })
        if (!this.list[i].beginConfirmTime) return this.$tip.warning({ message: `第${i + 1}项任务请输入发送时间提醒` })
        if (!this.list[i].intervalTime) return this.$tip.warning({ message: `第${i + 1}项任务请输入监督间隔` })
        if (!this.list[i].beginStartTime) return this.$tip.warning({ message: `第${i + 1}项任务请输入发送准备的消息` })
      }
      const tasks = []
      this.list.forEach(item => {
        console.log('item.assignedBy', item.assignedBy)
        tasks.push({
          taskTemplateId: item.templateId,
          assignedBy: item.assignedBy[2],
          beginCreateTime: item.beginCreateTime,
          beginConfirmTime: item.beginConfirmTime,
          beginStartTime: item.beginStartTime,
          content: item.content,
          endTime: item.endTime,
          storeId: item.storeId,
          intervalTime: item.intervalTime,
          isComfirm: item.isComfirm,
          startTime: item.startTime,
          startDate: item.startDate,
          endDate: item.endDate,
          title: item.title
        })
      })
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.visible = true
          this.isWorking = true
          if (this.form.id) {
            tasks.forEach(item => {
              this.info.content = item.content
              this.info.storeId = item.storeId
              this.info.assignedBy = item.assignedBy
              this.info.startTime = item.startTime
              this.info.endTime = item.endTime
              this.info.startDate = item.startDate
              this.info.endDate = item.endDate
              this.info.beginCreateTime = item.beginCreateTime
              this.info.beginConfirmTime = item.beginConfirmTime
              this.info.beginStartTime = item.beginStartTime
              this.info.intervalTime = item.intervalTime
              this.info.isComfirm = item.isComfirm
            })
            updateById(this.info).then(res => {
              this.visible = false
              this.$tip.apiSuccess('编辑成功')
              this.$emit('success')
            })
              .catch(e => {
                this.$tip.apiFailed(e)
              })
              .finally(() => {
                this.isWorking = false
              })
          } else {
            create({ tasks }).then(res => {
              this.visible = false
              this.$tip.apiSuccess('新建成功')
              this.$emit('success')
            })
              .catch(e => {
                this.$tip.apiFailed(e)
              })
              .finally(() => {
                this.isWorking = false
              })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

  <style lang="scss" scoped>
    .itemTask {
      width: 100%;
      display: flex;
      flex-direction: column;
      .itemTask_head {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
          font-size: 16px;
          color: #222222;
          font-weight: 600;
        }
      }
      .itemTask_item {
        width: 100%;
        padding: 10px 10px 0 10px;
        box-sizing: border-box;
        background-color: #ececec;
        margin-bottom: 10px;
        border-radius: 15px;
        position: relative;
        &:last-child {
          margin-bottom: 0 !important;
        }
        .itemTask_item_dele {
          position: absolute;
          right: 5px;
          top: 5px;
        }
      }
    }
  </style>
