<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="门店名称" prop="storeName">
        <el-input v-model="form.storeName" placeholder="请输入门店名称" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaStoreWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        storeName: ''
      },
      // 验证规则
      rules: {
        storeName: [
          { required: true, message: '请输入门店名称' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/ext/store',
      'field.id': 'id'
    })
  }
}
</script>
