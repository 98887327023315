import request from '@/utils/request'

// 查询列表树
export function pagePofetchTree (data) {
  return request.post('/system/position/tree', data)
}

// 查询列表树
export function fetchList (data) {
  return request.post('/system/position/pagePo', data)
}

// 查询岗位人员
export function users (data) {
  return request.post('/system/position/users', data)
}

// 通过条件查询查询岗位列表
export function findList (data) {
  return request.post('/system/position/findList', data)
}

// 查询列表
export function pagePo (data) {
  return request.post('/system/position/list', data)
}

// 新建
export function create (data) {
  return request.post('/system/position/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/system/position/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/system/position/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/system/position/delete/batch', {
    params: {
      ids
    }
  })
}
