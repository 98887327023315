<template>
    <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="门店" prop="storeId">
          <el-select v-model="form.storeId" placeholder="请选择门店">
            <el-option
              v-for="item in store()"
              :key="item.id"
              :label="item.storeName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入任务标题" v-trim/>
        </el-form-item>
        <el-form-item label="任务内容" prop="content">
          <el-input v-model="form.content" :rows="5" type="textarea" placeholder="请输入任务内容" v-trim/>
        </el-form-item>
      </el-form>
    </GlobalWindow>
  </template>
  
  <script>
  import BaseOpera from '@/components/base/BaseOpera'
  import GlobalWindow from '@/components/common/GlobalWindow'
  export default {
    name: 'OperaTaskTemplateWindow',
    extends: BaseOpera,
    components: { GlobalWindow },
    data () {
      return {
        // 表单数据
        form: {
          id: null,
          title: '',
          content: '',
          storeId: ''
        },
        // 验证规则
        rules: {
            title: [
              { required: true, message: '请输入任务标题' }
            ],
            content: [
              { required: true, message: '请输入任务内容' }
            ],
            storeId: [
              { required: true, message: '请选择门店名称' }
            ]
        }
      }
    },
    inject: ['store'],
    created () {
      this.config({
        api: '/ext/taskTemplate',
        'field.id': 'id'
      })
    }
  }
  </script>
  