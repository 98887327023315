import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/ext/taskRoutine/pageByUser', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/ext/taskRoutine/create', data)
}

// 根据条件查询任务详情
export function findOne (id) {
  return request.get(`/ext/taskRoutine/findOne?id=${id}`)
}

// 修改
export function updateById (data) {
  return request.post('/ext/taskRoutine/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/ext/taskRoutine/delete/${id}`)
}

// 获取当前老板下所有的员工信息列表（树状结构）
export function treeUserList (params) {
  return request.get('/system/user/treeUserList', { params })
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/ext/taskRoutine/delete/batch', {
    params: {
      ids
    }
  })
}
