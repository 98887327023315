<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="门店" prop="storeId" required>
        <el-select v-model="form.storeId" placeholder="请选择门店">
          <el-option
            v-for="item in store()"
            :key="item.id"
            :label="item.storeName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="岗位编码" prop="code" required>
        <el-input v-model="form.code" placeholder="请输入岗位编码" v-trim maxlength="50"/>
      </el-form-item>
      <!-- <el-form-item label="门店" prop="storeId" required>
        <el-select v-model="form.storeId" placeholder="请选择门店">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.storeName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="岗位名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入岗位名称" v-trim maxlength="50"/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { fetchListAll } from '@/api/ext/store'
// import PositionSelect from './PositionSelect'
export default {
  name: 'OperaPositionWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 需排除选择的岗位ID
      excludePositionId: null,
      options: [],
      // 表单数据
      form: {
        id: null,
        parentId: null,
        storeId: '',
        code: '',
        name: '',
      },
      // 验证规则
      rules: {
        code: [
          { required: true, message: '请输入岗位编码' }
        ],
        storeId: [
          { required: true, message: '请选择门店' }
        ],
        name: [
          { required: true, message: '请输入岗位名称' }
        ],
      }
    }
  },
  inject: ['store'],
  methods: {
    /**
     * 打开窗口
     *
     * @param title 窗口标题
     * @param target 行对象（仅编辑需该参数）
     * @param parent 新建时的上级岗位对象
     */
    open (title, target, parent) {
      this.title = title
      this.visible = true
      // 查询所有门店
      fetchListAll()
        .then(res => {
          this.options = res
        })
      // 新建
      if (target == null) {
        this.excludePositionId = null
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form.id = null
          this.form.parentId = parent == null ? null : parent.id
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        this.excludePositionId = target.id
        for (const key in this.form) {
          this.form[key] = target[key]
        }
      })
    }
  },
  created () {
    this.config({
      api: '/system/position'
    })
  }
}
</script>
