import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/job/page', data, {
    trim: true
  })
}

// 查询所有
export function fetchAll () {
  return request.get('/system/job/all')
}

// 创建  .twoFA()
export function create (data) {
  return request.post('/system/job/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/system/job/updateById', data)
}

// 修改状态
export function updateStatus (data) {
  return request.post('/system/job/updateStatus', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/system/job/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/system/job/delete/batch', {
    params: {
      ids
    }
  })
}

// 触发JOB   .twoFA()
export function trigger (data) {
  return request.post('/system/job/trigger', data)
}
