<template>
    <TableLayout :permissions="['ext:store:query']">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
        <el-form-item label="门店编码" prop="storeCode">
          <el-input v-model="searchForm.storeCode" placeholder="请输入门店编码" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="门店名称" prop="storeName">
          <el-input v-model="searchForm.storeName" placeholder="请输入门店名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->
      <template v-slot:table-wrap>
        <ul class="toolbar" v-permissions="['ext:store:create', 'ext:store:delete']">
          <li><el-button type="primary" @click="$refs.operaStoreWindow.open('新建门店信息表')" icon="el-icon-plus" v-permissions="['ext:store:create']">新建</el-button></li>
          <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['ext:store:delete']">删除</el-button></li>
        </ul>
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="id" label="门店ID" min-width="100px" show-overflow-tooltip></el-table-column>
          <el-table-column prop="storeCode" label="门店编码" min-width="100px"></el-table-column>
          <el-table-column prop="storeName" label="门店名称" min-width="100px"></el-table-column>
          <el-table-column prop="responsibilityUserName" label="第一责任人" min-width="100px"></el-table-column>
          <el-table-column prop="createName" label="创建人" min-width="100px"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="100px"></el-table-column>
          <!-- <el-table-column prop="updateName" label="更新人" min-width="100px"></el-table-column>
          <el-table-column prop="updateTime" label="更新时间" min-width="100px"></el-table-column> -->
          <el-table-column
            v-if="containPermissions(['ext:store:update', 'ext:store:delete'])"
            label="操作"
            min-width="120"
            fixed="right"
          >
            <template slot-scope="{row}">
              <el-button type="text" @click="$refs.operaStoreWindow.open('编辑门店信息表', row)" icon="el-icon-edit" v-permissions="['ext:store:update']">编辑</el-button>
              <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['ext:store:delete']">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        >
        </pagination>
      </template>
      <!-- 新建/修改 -->
      <OperaStoreWindow ref="operaStoreWindow" @success="handlePageChange"/>
    </TableLayout>
  </template>
  
  <script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import Pagination from '@/components/common/Pagination'
  import OperaStoreWindow from '@/components/ext/OperaStoreWindow'
  export default {
    name: 'Store',
    extends: BaseTable,
    components: { TableLayout, Pagination, OperaStoreWindow },
    data () {
      return {
        // 搜索
        searchForm: {
          storeCode: '',
          storeName: ''
        }
      }
    },
    created () {
      this.config({
        module: '门店信息表',
        api: '/ext/store',
        'field.id': 'id',
        'field.main': 'id'
      })
      this.search()
    }
  }
  </script>
  