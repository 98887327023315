<template>
    <TableLayout :permissions="['ext:tasktemplate:query']">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
        <el-form-item label="门店" prop="storeId">
          <el-select v-model="searchForm.storeId" clearable placeholder="请选择门店" @change="search">
            <el-option
              v-for="item in store"
              :key="item.id"
              :label="item.storeName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务标题" prop="title">
          <el-input v-model="searchForm.title" placeholder="请输入任务标题" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->
      <template v-slot:table-wrap>
        <ul class="toolbar" v-permissions="['ext:tasktemplate:create', 'ext:tasktemplate:delete']">
          <li><el-button type="primary" @click="$refs.operaTaskTemplateWindow.open('新建任务模板(类型)')" icon="el-icon-plus" v-permissions="['ext:tasktemplate:create']">新建</el-button></li>
          <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['ext:tasktemplate:delete']">删除</el-button></li>
        </ul>
        <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="title" show-overflow-tooltip label="任务标题" min-width="100px"></el-table-column>
          <el-table-column prop="content" show-overflow-tooltip label="任务内容" min-width="100px"></el-table-column>
          <el-table-column prop="belongBossName" show-overflow-tooltip label="所属Boss" min-width="100px"></el-table-column>
          <el-table-column prop="createUserName" show-overflow-tooltip label="创建人" min-width="100px"></el-table-column>
          <el-table-column prop="createTime" show-overflow-tooltip label="创建时间" min-width="100px"></el-table-column>
          <el-table-column
            v-if="containPermissions(['ext:tasktemplate:update', 'ext:tasktemplate:delete'])"
            label="操作"
            min-width="120"
            fixed="right"
          >
            <template slot-scope="{row}">
              <el-button type="text" @click="$refs.operaTaskTemplateWindow.open('编辑任务模板(类型)', row)" icon="el-icon-edit" v-permissions="['ext:tasktemplate:update']">编辑</el-button>
              <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['ext:tasktemplate:delete']">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        >
        </pagination>
      </template>
      <!-- 新建/修改 -->
      <OperaTaskTemplateWindow ref="operaTaskTemplateWindow" @success="handlePageChange"/>
    </TableLayout>
  </template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaTaskTemplateWindow from '@/components/ext/OperaTaskTemplateWindow'
import { fetchListAll } from '@/api/ext/store.js'
export default {
  name: 'TaskTemplate',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaTaskTemplateWindow },
  data () {
    return {
      store: [],
      // 搜索
      searchForm: {
        title: '',
        storeId: '',
        belongBoss: ''
        // belongBoss: this.$store.state.userInfo.belongBoss ? this.$store.state.userInfo.belongBoss : this.$store.state.userInfo.id
      }
    }
  },
  provide: function () {
    return {
      store: () => this.store
    }
  },
  created () {
    this.config({
      module: '任务模板(类型)',
      api: '/ext/taskTemplate',
      'field.id': 'id',
      'field.main': 'id'
    })
    console.log(this.$store.state.userInfo)
    this.search()
    fetchListAll({})
      .then(res => {
        this.store = res
      })
  }
}
</script>
